.formWrapper {
    padding: 1rem;
    margin: 2rem auto;
    background: transparent;
    box-shadow: 0px 0px 35px 17px rgba(0, 0, 0, 0.2);
    border-radius: 2rem;
}

.formWrapper .formHeader {
    color: #083F88;
    font-weight: 700;
    font-size: 2.25rem;
    text-align: center;
}

#reference {
    display: none;
}

.formWrapper .formFooter a {
    color: #FFFFFF;
    font-weight: 700;
    font-size: 2rem;
    text-decoration: none;
}

.formBody .consent-font {
    color: #FFFFFF;
    font-size: 12px;
}

.borrowerLeadGeneration .consent-font .form-link {
    color: #083F88;
    font-weight: 500;
}



.formWrapper .btn-submit:hover,
.formWrapper .btn-submit:focus {
    background: linear-gradient(180deg, #00abe4d5 0%, #083f88d2 100%);
}

.form-control {
    height: auto !important;
    border: 1px solid #083F88 !important;
    border-radius: 0.45rem !important;
}

.form-select {
    border: 1px solid #083F88 !important;
}

.formWrapper .form-check {
    margin-right: 2rem;
    margin-left: 2rem;
}
   

.formWrapper a {
    color: #FFFFFF !important;
}

.formWrapper .consentSmall {
    color: #FFFFFF !important;
}

.formWrapper #showConsentText {
    color: #FFFFFF !important;
}

@media(min-width:767.7px) {
    .formWrapper .btn-submit {
        background: linear-gradient(180deg, #00AAE4 0%, #083F88 100%);
        padding: .35rem 2.25rem !important;
        font-size: 1.25rem !important;
        font-weight: 700 !important;
        border: 0;
        border-radius: .75rem;
    }
}

@media(max-width:767.7px) {
    .formWrapper .btn-submit {
        background: linear-gradient(180deg, #00AAE4 0%, #083F88 100%);
        font-weight: 700 !important;
        border: 0;
        width: 150px !important;
        border-radius: 0.75rem;
    }

    

    .formWrapper .btn-success {
        left: 55% !important;
    }

    .formWrapper .form-check {
        margin-right: 1rem;
    }
    .formWrapper .consentSmall {
        margin-left: 0.5rem;
    }
    .formBody .consent-font {
        margin-left: 0.5rem;
    }

    
}

@media(min-width: 320px) and (max-width: 389.7px) {
    .formWrapper .btn-submit {
        width: 150px !important;
    }
}