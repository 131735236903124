body {
    margin: 0;
    /* margin-top: 65px; */
    font-family: 'Poppins', sans-serif;
    /* Use Poppins font for the body */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
  code {
    font-family: 'Poppins', monospace;
  }
  
  .scrollToTop {
    padding: 10px;
  }
  
  .overRideTransition {
    color: #23527c;
  }
  
  #ratesWrapper {
    position: relative;
    display: flex;
    background-color: #fff;
    background-image: url('https://staging-cdn.swmc.com/lowrates/bgLRRatesdesktop.webp');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: bottom;
    margin-top: 60px;
  }
  
  #ratesWrapper #headerFooterbg {
    position: absolute;
    z-index: -1;
    bottom: -2%;
    height: 100px;
    width: 50%;
    background-image: url('https://resources.swmc.com/swmc-images/Rectangle 13.png');
    background-size: cover;
    background-position: bottom;
  }
  
  .btn-cq {
    background: #00AAE4 !important;
    color: #fff;
    display: block;
    border: 0;
    padding: 10px;
    margin: 10px auto;
    text-align: center;
    padding: .5rem 1.5rem !important;
    font-size: 1rem !important;
    font-weight: 700 !important;
    border: none !important;
    border-radius: 0.5rem !important;
  }
  
  .btn-cq:hover,
  .btn-cq:focus {
    background: linear-gradient(180deg, #00abe4d5 0%, #083f88d2 100%) !important;
  }
  
  .longCyanButton {
    background-color: #0053a6 !important;
    font-size: 20px;
    color: #fff;
    margin: 10px auto;
    font-weight: 600;
    border-radius: 5px
  }
  
  .greyButton {
    background-color: #737373;
    color: #fff;
    display: block;
    font-size: 20px;
    width: 100%;
    border: 0;
    max-width: 200px;
    padding: 10px;
    margin: 10px auto;
    text-align: center;
    font-weight: 600;
    border-radius: 5px
  }
  
  .cyanButton:hover,
  .greyButton:hover,
  .longCyanButton:hover {
    background-color: #023368 !important;
    color: #fff
  }
  
  h1,
  h2,
  h3,
  h4,
  h5 {
    color: #004ca4;
  }
  
  .h5,
  h5 {
    font-size: 1.15rem;
  }
  
  .textBlue {
    color: #00AAE4;
  }
  
  h4.fontSizeRateAprText {
    color: #083F88;
  }
  
  h2.reducefontSize,
  h3.lbl-Apply {
    color: #0e3e9b;
    font-size: 1.3rem;
    padding-top: 0.5rem;
  }
  
  .btnApplyNow .textBlue.Poppins-bold {
    font-weight: 700;
  }
  
  .btnApplyNow h2.reducefontSize {
    font-weight: 500;
    color: #083F88;
    font-size: 1.5rem !important;
  }
  
  @media (min-width: 1200px) and (max-width: 1400px) {
    .btnApplyNow h2.reducefontSize {
      font-size: 1.23rem !important;
    }
  
    .h5,
    h5 {
      font-size: 1rem !important;
    }
  
    #ratesWrapper {
      height: auto !important;
    }
  }
  
  .lbl-Apply {
    border-top: 2px #D9D9D9 solid !important;
  }
  
  .textBlue td {
    color: #0e3e9b;
  }
  
  .applyNowWrapper .modal-content {
    margin-top: 4rem;
  }
  
  .btnApplyNow {
    width: 100%;
    color: #0e3e9b;
    padding: 1rem 1rem 0.5rem !important;
    background: #fff !important;
    transition: .6s !important;
    border-color: #fff !important;
    border-radius: 1rem !important;
    box-shadow: 0px 0px 20px 11px rgba(0, 70, 228, 0.15) !important;
  }
  
  
  .btnApplyNow.btn-primary:hover,
  .btnApplyNow.btn-primary:focus {
    background-color: #3074b7 !important;
    color: #fff !important;
    border-color: #3074b7 !important;
  }
  
  .applyNowWrapper .modal-header {
    background-color: #fff !important;
    border-bottom: none !important;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    padding-bottom: 0;
  }
  
  
  
  .borrowerLeadGeneration .form-check-label {
    padding: 0 1.5rem 0 0 !important;
  }
  
  .borrowerLeadGeneration .btn-start {
    background-color: #083f88;
    border-color: #083f88;
    color: #ffffff !important;
  }
  
  .borrowerLeadGeneration .btn-start:hover {
    background-color: #2ba8e0;
    border-color: #2ba8e0;
  }
  
  .backToHome,
  .multi-step .footer-buttons .btn-primary {
    font-size: 20px !important;
    font-weight: 600 !important;
    width: auto;
    background: transparent !important;
    color: #054d9a !important;
    border: 2px solid #fff !important;
    margin: 0 16px 0 0 !important;
    padding: 9px 20px !important;
    line-height: 14px !important;
    border-radius: 2px !important;
    transition: all .4s;
    -moz-transition: all .4s;
    -ms-transition: all .4s;
    -o-transition: all .4s;
    -webkit-border-radius: 2px;
  }
  
  
  .multi-step .footer-buttons #prev-button,
  .multi-step .footer-buttons #next-button {
    border-radius: 0px !important;
    height: unset !important;
    top: unset !important;
    transform: translateY(0) !important;
  }
  
  
  .ratesSection .nav-tabs {
    border-bottom: none;
  }
  
  .tab-content>.active {
    margin: 0 -2rem;
  }
  
  .nav-tabs .nav-link {
    border: none !important;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
    color: #6c757d !important;
    font-weight: bolder;
  }
  
  
  
  #loan-purpose-tabs-tabpane-purchase {
    /* background-image: url('https://staging-cdn.swmc.com/lowrates/rateheadingbackground.png') !important; */
    background-repeat: no-repeat;
    background-size: 95% 2.4%;
    background-position: center 16%;
    position: relative;
    padding: 10px;
  }
  
  #loan-purpose-tabs-tabpane-refinance {
    /* background-image: url('https://staging-cdn.swmc.com/lowrates/rateheadingbackground.png') !important; */
    background-repeat: no-repeat;
    background-size: 95% 2.4%;
    background-position: center 16%;
    position: relative;
    padding: 10px;
  }
  
  #loan-purpose-tabs-tabpane-homeEquity {
    background-image: url('https://staging-cdn.swmc.com/lowrates/rateheadingbackground.png') !important;
    background-repeat: no-repeat;
    background-size: 95% 2.4%;
    background-position: center 0%;
    position: relative;
    padding: 10px;
  }
  
  .ratesSection .nav-tabs .nav-link {
    width: 160px;
    color: #00AAE4 !important;
    padding: .2rem 0.75rem;
    border-radius: 0;
    margin-right: .25rem;
    font-size: 1rem;
    font-weight: 500;
    text-align: center !important;
    /* background: rgba(0, 170, 228, 0.60) !important; */
  }
  
  @media (min-width: 991px) and (max-width: 1200px) {
    #ratesWrapper {
      height: auto !important;
    }
  
    .textBlue h4 {
      font-size: 22px;
    }
  
    .ratesSection .nav-tabs .nav-link {
      width: 150px !important;
      margin-right: 0rem !important;
      font-size: 0.9rem !important;
    }
  
    .btnApplyNow h2.reducefontSize {
      font-size: 0.95rem !important;
    }
  
    .btnApplyNow .textBlue.Poppins-bold {
      font-size: 16px !important;
    }
  
    h2.reducefontSize,
    h3.lbl-Apply {
      font-size: 1.2rem !important;
    }
  
    .mainBody h6,
    #home-products-tabs-tabpane-va h4 {
      font-size: 0.6rem !important;
    }
  
    #loan-purpose-tabs-tabpane-purchase {
      background-size: 100% 2.4% !important;
    }
  
    #loan-purpose-tabs-tabpane-refinance {
      background-size: 100% 2.4% !important;
    }
  
    #loan-purpose-tabs-tabpane-homeEquity {
      background-size: 100% 2.4% !important;
    }
  
    .h5,
    h5 {
      font-size: 0.85rem;
    }
  }
  
  .ratesSection .nav-tabs .nav-link:focus {
    outline-color: none !important;
  }
  
  .ratesSection .nav-tabs button.nav-link.active:not(#loan-purpose-tabs-tab-purchase, #loan-purpose-tabs-tab-refinance, #loan-purpose-tabs-tab-homeEquity) {
    border-color: initial;
    color: #FFFFFF !important;
    background-color: initial !important;
    border: none !important;
    background-image: url('https://staging-cdn.swmc.com/lowrates/ratesheadinghead.png') !important;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    position: relative;
    z-index: 2;
  }
  
  #loan-purpose-tabs-tab-purchase.active {
    border-color: initial;
    color: #FFFFFF !important;
    border: none !important;
    background-color: initial !important;
    border-right: 1px solid #FFFFFF !important;
  }
  
  #loan-purpose-tabs-tab-refinance.active {
    border-color: initial;
    color: #FFFFFF !important;
    border: none !important;
    background-color: initial !important;
    border-left: 1px solid #FFFFFF !important;
  }
  
  #loan-purpose-tabs-tab-homeEquity.active {
    border-color: initial;
    color: #FFFFFF !important;
    border: none !important;
    background-color: initial !important;
    border-left: 1px solid #FFFFFF !important;
    border-right: 1px solid #FFFFFF !important;
  }
  
  .rate-title-bg-color.rate-title-bg-color {
    margin-bottom: 1rem;
    box-shadow: none !important;
  }
  
  .mainBody h6,
  #home-products-tabs-tabpane-va h4 {
    color: #FFFFFF;
    font-size: 0.8rem;
  }
  
  .ratesTitle {
    width: 100%;
  }
  
  .Poppins-med hr {
    color: transparent;
  }
  
  .text-white {
    font-size: 2rem;
  }
  
  #todayRatesTitle {
    margin-top: 2rem !important;
  }
  
  .btnApplyNow .textBlue.Poppins-bold {
    font-size: 20px;
  }
  
  @media (max-width: 767.7px) {
    #ratesWrapper {
      height: auto;
    }
  
    .ratesSection .nav-tabs .nav-link {
      width: 100% !important;
      margin: 0px 9px !important;
    }
  
    .tab-content>.active {
      margin: 0 -1rem;
    }
  
    #todayRatesTitle {
      display: block;
      margin-left: auto;
      margin-right: auto;
    }
  
    .btnApplyNow {
      width: 85% !important;
    }
  
    #loan-purpose-tabs-tabpane-purchase {
      background-size: 95% 1% !important;
      background-position: center 16% !important;
    }
  
    #loan-purpose-tabs-tabpane-refinance {
      background-size: 95% 1% !important;
      background-position: center 9% !important;
    }
  
    #loan-purpose-tabs-tabpane-homeEquity {
      background-size: 95% 1% !important;
      background-position: center 9% !important;
    }
  
    .mainBody h6 {
      font-size: 0.75rem !important;
    }
  }
  
  @media (max-width: 375.7px) {
    .ratesSection .nav-tabs .nav-link {
      width: 100% !important;
      margin: 0px 0px !important;
    }
  }
  
  @media (min-width: 376px) and (max-width: 389.7px) {
    .ratesSection .nav-tabs .nav-link {
      width: 100% !important;
      font-size: 0.8rem;
    }
  }
  
  @media (min-width: 320px) and (max-width: 350.7px) {
    .ratesSection .nav-tabs .nav-link {
      width: 100% !important;
      margin: 0px 0px !important;
      padding: 0px 4px !important;
      font-size: 0.8rem;
    }
  }
  
  @media (min-width: 768px) and (max-width: 991.7px) {
  
    #ratesWrapper {
      height: auto !important;
    }
  
    #todayRatesTitle {
      margin-top: 1rem !important;
    }
  
    .tab-content .poppins-black {
      display: contents !important;
    }
  
    .textBlue {
      font-size: 18px !important;
    }
  
    .ratesSection .nav-tabs .nav-link {
      width: 100% !important;
      font-size: 0.75rem !important;
      margin-right: 2rem;
    }
  
    #loan-purpose-tabs-tabpane-purchase {
      background-size: 100% 2.4%;
      background-position: center 16.5%;
    }
  
    #loan-purpose-tabs-tabpane-refinance {
      background-size: 100% 2.4%;
      background-position: center 16.5%;
    }
  
    #loan-purpose-tabs-tabpane-homeEquity {
      background-size: 100% 2.4%;
      background-position: center 8.5%;
    }
  
  
    .btnApplyNow {
      width: 90%;
      margin-top: 1rem;
    }
  
    .tab-content>.active {
      margin: 0 -2rem !important;
    }
  
    .btnApplyNow .textBlue.Poppins-bold {
      font-size: 15px !important;
    }
  
    .btnApplyNow h2.reducefontSize {
      font-weight: 900;
      font-size: 0.85rem !important;
    }
  
    .fontSizeRateAprText {
      font-size: 0.6rem !important;
    }
  
    h2.reducefontSize,
    h3.lbl-Apply {
      font-size: 0.7rem !important;
    }
  
    .btn-cq {
      padding: 10px 10px !important;
      /* margin-right: -4.5rem; */
      font-size: 0.75rem !important;
    }
  
    .mainBody h6,
    #home-products-tabs-tabpane-va h4 {
      color: #FFFFFF;
      font-size: 10px;
    }
  
    .h5,
    h5 {
      font-size: 8px !important;
    }
  }
  
  
  
  
  .step-progress h2 {
  
    color: #FFFFFF !important;
  }
  
  .step-progress h4 {
    color: #FFFFFF !important;
    font-size: 16px;
  }
  
  .radImg {
    transform: 0 !important;
  }
  
  .radImg2 {
    max-width: 90px !important;
    top: 15% !important;
  }
  
  .step-progress label {
    width: 90px !important;
  }
  
  label[for="sfrProperty"].form-check-label,
  label[for="condoProperty"].form-check-label,
  label[for="unitsProperties"].form-check-label,
  label[for="manuProperty"].form-check-label {
    font-size: 9px !important;
  }
  
  .multi-step .pb-md-0 {
    margin-left: -3rem;
  }
  
  .form-check {
    padding-left: 0em;
  }
  
  .step-progress h5 {
    color: #FFFFFF;
    font-size: 20px;
  }
  
  button.backToHome {
    background: #fff !important;
    margin-top: 20px !important;
    margin-left: -5px !important;
  }
  
  .multi-step .footer-buttons #next-button,
  .multi-step .footer-buttons #prev-button,
  .backToHome {
    background: #fff !important;
    color: #054d9a !important;
  }
  
  .multi-step .footer-buttons #next-button:hover,
  .multi-step .footer-buttons #prev-button:hover,
  .backToHome {
    background: #054d9a !important;
    color: #fff !important;
  }
  
  .step-progress .form-label {
    margin-top: -7rem;
  }
  
  th.textBlue.poppins-med h5 {
    color: #083F88;
    font-size: 16px !important;
  }
  
  
  @media(min-width: 992px)and (max-width:1199.7px) {
    .radImg2 {
      max-width: 70px !important;
      top: 20% !important;
    }
  
    .multi-step .footer-buttons #next-button {
      position: absolute;
      right: -16px !important;
    }
  
    .step-progress h2 {
      font-size: 1rem;
    }
  
  
  
    input[placeholder="Property Zip Code"]::placeholder {
      font-size: 10px !important;
    }
  
    .step-progress label {
      width: 70px !important;
    }
  
    .step-progress .form-select {
      font-size: 11px !important;
    }
  
    .step-progress h4 {
      font-size: 11px;
    }
  }
  
  @media(min-width: 1200px)and (max-width:1399.7px) {
    .step-progress .form-select {
      font-size: 13px !important;
    }
  
    .multi-step .footer-buttons #next-button {
      right: -20px !important;
    }
  
    input[placeholder="Property Zip Code"]::placeholder {
      font-size: 13px !important;
    }
  
    .step-progress h2 {
      font-size: 26px;
    }
  
    .step-progress h4 {
      font-size: 14px;
    }
  
    .radImg2 {
      max-width: 80px !important;
      top: 20% !important;
    }
  
    .step-progress label {
      width: 80px !important;
    }
  
    label[for="sfrProperty"].form-check-label,
    label[for="condoProperty"].form-check-label,
    label[for="unitsProperties"].form-check-label,
    label[for="manuProperty"].form-check-label {
      font-size: 8px !important;
    }
  }
  
  @media (min-width: 320px) and (max-width: 991.7px) {
    .multi-step .footer-buttons {
      margin-top: 10px !important;
    }
  
    .step-progress .form-label {
      margin-bottom: -5.5rem !important;
    }
  }
  
  @media screen and (max-width: 475px) {
    .formBody .btn-submit {
      width: 160px !important;
    }
  
    .applyNowWrapper .form-check {
      margin-left: 1rem !important;
    }
  
    .borrowerLeadGeneration .btnAdjustDiv {
      left: auto !important;
      margin-left: auto !important;
      margin-right: auto !important;
      display: block !important;
    }
  
    .borrowerLeadGeneration .backBtnDiv {
      z-index: 10 !important;
    }
  
    .borrowerLeadGeneration .btn-success {
      left: 30% !important;
      position: relative !important;
    }
  
    .borrowerLeadGeneration .consent-font {
      color: black;
      font-size: 12px;
    }
  }
  
  #productResult h2 {
    color: #083f88 !important;
  }
  
  #productResult h4 {
    color: #00aae4 !important;
  }
  
  
  @media screen and (max-width: 767.7px) {
    #loan-purpose-tabs-tabpane-purchase {
      /* background-image: none !important; */
      border-top: 1px solid rgba(255, 255, 255, 0.5) !important;
    }
  
    #loan-purpose-tabs-tabpane-refinance {
      /* background-image: none !important; */
      border-top: 1px solid rgba(255, 255, 255, 0.5) !important;
    }
  
    #loan-purpose-tabs-tabpane-homeEquity {
      background-image: none !important;
      border-top: 1px solid rgba(255, 255, 255, 0.5) !important;
    }
  
    /* .ratesSection .nav-tabs:not(#loan-purpose-tabs-tab-homeEquity.active) {
      border-bottom: 1px solid rgba(255, 255, 255, 0.5) !important;
    } */
  
  }
  
  @media (min-width: 992px) and (max-width:1199.7px) {
    .text-danger {
      font-size: 11px !important;
    }
  }
  
  @media (min-width: 1200px) and (max-width:1400.7px) {
    .text-danger {
      font-size: 13px !important;
    }
  }
  
  @media (min-width: 1400px) and (max-width:1650.7px) {
    .text-danger {
      font-size: 15px !important;
    }
  }
  
  @media screen and (min-width: 767.7px) {
    .checkIConsent {
      padding-left: 1rem !important;
    }
  }
  
  
  
  #products-purchase-tabs-tabpane-fha h4 {
    color: #00aae4;
  }
  #products-purchase-tabs-tabpane-va h4 {
    color: #00aae4;
  }
  #products-purchase-tabs,
  #products-refinance-tabs {
    position: relative;
    border-bottom: 1px solid #FFFFFF !important;
  }
  
  #products-purchase-tabs::before,
  #products-purchase-tabs::after,
  #products-refinance-tabs::before,
  #products-refinance-tabs::after {
    content: '';
    position: absolute;
    width: 6px; 
    height: 6px; 
    background-color: #FFFFFF; 
    border-radius: 50%; 
  }
  
  #products-purchase-tabs::before,
  #products-refinance-tabs::before {
    top: 94%; 
    /* transform: translateY(-50%); */
    left: -5px;
  }
  
  #products-purchase-tabs::after,
  #products-refinance-tabs::after {
    top: 94%; 
    /* transform: translateY(-50%); */
    right: -5px; 
  }
  
  @media screen and (max-width: 374.7px) {
    .borrowerLeadGeneration .btn-success {
      left: 40% !important;
      position: relative !important;
  }
  }