.servicesCardContainer {
    margin-top: 3rem;
    /* margin-left: 3rem;
    margin-right: 3rem; */
    margin-bottom: 3rem;
}

#resources-wrapper {
    position: relative;
    display: flex;
    align-items: center;
    /* background: rgba(0, 170, 228, 0.5); */
}

#resources-wrapper .leftTopBlueDots {
    position: absolute;
    left: 2%;
    width: 5%;
}

#resourcesContainer .card {
    border: 2px solid #b9daee;
    border-radius: 0.35rem;
    /* filter: drop-shadow(0px 11px 21px rgba(0, 0, 0, 0.25)); */
}


#resourcesContainer .card figure {
    position: relative;
    margin: -1px -1px 44px -1px;
    border-top-left-radius: .35rem;
    border-top-right-radius: .35rem;
    background: linear-gradient(to bottom, #374350 0, #2f3b49 100%);
}

#resourcesContainer .card figure:after {
    display: inline-block;
    content: "";
    position: absolute;
    width: 70px;
    border-top: 1px solid #3dbaf4;
    border-bottom: 1px solid #3a93d4;
    height: 1px;
    background: #3caae7;
    bottom: -1px;
    left: 50%;
    margin-left: -36px;
    opacity: 0;
    transition: all .2s ease-in-out;
}

#resourcesContainer .card:hover figure:after {
    opacity: 1;
}

#resourcesContainer .card-body {
    color: #114c6a;
    padding-top: 0;
    background-color: #fff;
    border-bottom-left-radius: .35rem;
    border-bottom-right-radius: .35rem;
}

#resourcesContainer a {
    color: #083F88;
    text-decoration: none;
}

#resourcesContainer a:hover .cardImg {
    opacity: .75;
}

#resourcesContainer .badge-theme {
    position: absolute;
    color: #fff;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    left: 50%;
    top: 100%;
    margin: -25px 0 0 -25px;
    border: 1px solid #fff;
    transition: all .3s ease-in-out;
    background: linear-gradient(to bottom, #3dbaf4 0, #3db2ed 20%, #3a8ed1 76%, #3983c7 100%);
}

#resourcesContainer .card:hover .badge-theme {
    top: 50%;
}

@media only screen and (max-width:767.7px) {
    #resourcesContainer .card {
        margin: 0 auto 40px;
        border: 0;
        box-shadow: 0px 5px 10px 5px rgb(0 0 0 / 20%);
    }

    #resourcesContainer .card-body {
        color: #083F88;
    }

    .contentcardinfo {
        font-size: 1.5rem !important;
        font-weight: 600 !important;
    }

    #resourcesContainer .card figure {
        margin: 10px 10px 40px 10px;
    }
}

@media(min-width: 768px)and (max-width:991.7px) {
    .contentcardinfo {
        font-size: 1.1rem !important;
        font-weight: 600 !important;
    }
}