

.title h1{
    font-size: 44px;
    font-family: "Graphik Bold";
    margin-bottom: 35px;
    padding-top: 35px;
    text-align: center;
    font-weight: 500;
    line-height: 1.2;
    margin-top: 35px;
}

.strong{
    font-weight: 700;
    font-size: 20px;
}

.facts_section{
    display: flex;
    border: 1px solid #dee2e6;
    margin-bottom: 45px;
}

.border{
    border: 1px solid #dee2e6;
}

.border_bottom_none{
    border-bottom: none !important;
}

.facts_section .facts_section_col{
    padding: 0;
}

.facts_section_row{
    border-bottom: 1px solid #dee2e6;
    padding: 0;
    align-items: center;
}

.facts_section_row_heading{
    font-weight: 700;
    font-size: 20px;
}

.facts_section_row .facts_section_row_heading,
.facts_section_row .facts_section_row_desc{
    /* padding: 1.5rem; */
    padding: 20px 25px;
}

.gray_row{
    background-color: rgba(172, 206, 224, 0.19);
}

p{
    font-size: 16px;
    font-family: "Poppins";
}

/* Share section STARTS */
.share_section {
    display: flex;
    border: 1px solid #dee2e6;
    margin-bottom: 45px;
    /* font-size: 1rem; */
}

.share_section h2{
    line-height: 1.2;
    margin-bottom: 0;
    font-size: 36px;
    font-family: "Graphik Web Bold";
    font-weight: 100;
    margin: 0;
}

.share_section_col.col,
.share_section.container,
.share_section.container-lg,
.share_section.container-md,
.share_section.container-sm,
.share_section.container-xs{
    padding: 0;
}

.share_section_row{
    align-items: center;
    border-top: 1px solid #dee2e6;
    /* border-bottom: 1px solid #dee2e6; */
}

.share_section_row.blue_row{
    line-height: 2rem;
    border: none
}

.share_section_row .share_section_row_desc,
.share_section_row .share_section_row_flag1,
.share_section_row .share_section_row_flag2{
    padding: 20px 25px;
    vertical-align: middle;
}

.blue_row{
    background-color: #3074b7;
    color: #ffffff;
}
.share_section_strong{
    font-weight: 700;
    font-size: 20px;
}

.light_gray{
    background-color: #f9f9f9;
}

@media (max-width: 900px){
    .share_section_row_desc{
        font-size: 1rem;
    }
    .share_section_row_flag1,
    .share_section_row_flag2{
        font-size: 0.9rem;
    }
}

@media (max-width: 500px){
    .share_section_row .share_section_row_desc,
    .share_section_row .share_section_row_flag1,
    .share_section_row .share_section_row_flag2{
        padding: 10px 15px;
    }
}
/* Share section ENDS */

/*  Contact section STARTS */
.contact_section {
    display: flex;
    border: 1px solid #dee2e6;
    margin-bottom: 45px;
    padding-right: 0;
    padding-left: 0;
    /* font-size: 1rem; */
}

/* .contact_section_row{
    display: block;
} */

.vertical_middle{
    display: flex;
    align-items: center;
}

.padded{
    padding: 20px 10px;
}

.contact_section li{
    font-size: 16px;
    font-family: "Poppins";
}

.contact_section ul{
    padding: 0;
    margin: 0;
    margin-bottom: 20px;
}
.contact_section h2{
    font-size: 36px;
    font-family: "Graphik Bold";
    font-weight: 100;
    margin: 0;
    margin-bottom: 15px;
}
.contact_section a{
    color: #007bff;
    text-decoration: none;
    background-color: transparent;
    font-family: "Poppins";
}

.text-white {
    color: #ffffff;
}
/* @media (max-width: 500px){
    .contact_section .padded{
        padding: 10px 10px;
    }
} */
/*  Contact section ENDS */