.contact_us_div {
    background-image: url('https://staging-cdn.swmc.com/lowrates/contactusbackground.webp');
    background-repeat: no-repeat;
    background-size: 100% 75%;
    margin-top: -3rem;
}

.contact_us_container {
    font-family: "Poppins";
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 950px;

}

.contact_form_container {
    font-family: "Poppins";
    width: 950px;
    margin-top: 7rem;
}

.contact_us_container a {
    font-family: "Poppins";
}

.contact_us_column {
    margin-top: 10rem;
}

.shadowcontainer {
    /* box-shadow: 0px 0px 10px 5px rgba(170, 234, 255, 1); */
    border: 10px solid #aeeaff;
}

.contactustext {
    text-align: center;
    color: #083f88;
    font-weight: 500;
}

.contactustextbigger {
    text-align: center;
    color: #00aae4;
    font-weight: 900;
}

.contact_form_div {
    background-image: url('https://staging-cdn.swmc.com/lowrates/contactformbackground.webp');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    position: relative;
    margin-top: -4rem;
    margin-bottom: 2rem;
}

.contactInfoColumn {
    text-align: center;
}

.contactInfoColumn h5 {
    color: #00AAE4;
    font-weight: 700;
}

.contactInfoColumn a {
    text-decoration: none;
    color: #083F88;
    font-weight: 700;
}

.icon_image {
    width: 40px;
    margin-bottom: 1rem;
}

.contactInfoColumn1 {
    position: relative;
}

.contactInfoColumn1::before,
.contactInfoColumn1::after {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    width: 1px;
    background-color: #000000;
}

.contactInfoColumn1::before {
    left: 0;
    height: 150px;
}

.contactInfoColumn1::after {
    right: 0;
    height: 150px;
}

@media(min-width: 992px)and (max-width:1199.7px) {
    .contact_us_container {
        width: 800px;

    }

    .contact_form_container {
        width: 800px;
    }
}

@media(min-width: 768px)and (max-width:991.7px) {

    .contact_us_div {
        background-image: none;
        margin-top: -4rem;
    }

    .contact_us_container {
        width: 700px;

    }

    .contact_form_container {
        width: 700px;
    }

    .contactInfoColumn a {
        font-size: 14px;
    }

    .contactInfoColumn h5 {
        font-size: 16px !important;
    }

}

@media(max-width:767.7px) {
    .contact_us_div {
        background-image: none;
        margin-top: -5rem;
    }

    .contact_us_container {
        width: auto;

    }


    .responsiveIframe {
        height: 200px;
    }


    .contact_form_container {
        width: auto;
    }

    .contactInfoColumn1 {
        position: relative;
    }

    .contactInfoColumn1::before,
    .contactInfoColumn1::after {
        content: "";
        position: absolute;
        left: 0;
        right: 0;
        height: 1px;
        background-color: #000000;
    }

    .contactInfoColumn1::before {
        margin-top: -1rem;
        top: 0;
        width: 200px;
        display: block;
        margin-left: auto;
        margin-right: auto;
    }

    .contactInfoColumn1::after {
        margin-top: 8.5rem;
        bottom: 0;
        width: 200px;
        display: block;
        margin-left: auto;
        margin-right: auto;
    }

    .contactInfoColumn {
        margin-bottom: 2rem;
        font-size: 1.25rem;
    }

    .contactustext {
        font-size: 1.60rem;
    }
    
    .contactustextbigger {
        font-size: 2.5rem;
        margin-bottom: 1rem;
    }

    .contactInfoColumn h5 {
        font-size: 1.5rem;
    }

    .contact_form_div {
        background-image: url('https://staging-cdn.swmc.com/lowrates/contactformbackgroundmobile.webp');
        background-repeat: no-repeat;
        background-size: 100% 100%;
        position: relative;
        margin-top: -4rem;
        margin-bottom: 2rem;
    }
}