#howItWorks-wrapper .layout-column {
    position: relative;
    margin-bottom: 20px;
    box-shadow: 0px 0px 35px 9px rgba(0, 0, 0, 0.2);
    background-color: #FFFFFF !important;
    border-radius: 15px;
    padding: 0px;
    background-position: left top;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 407px;
    height: auto;
}

.howitworksheading {
    color: #FFFFFF;
    font-size: 2.5rem;
    margin-top: 1rem;
    margin-bottom: -1.5rem;
}

#howItWorks-wrapper .content-boxes {
    background-color: rgba(255, 255, 255, 0);
    min-height: 339px;
    height: auto;
    overflow: visible;
    margin-bottom: 3rem;
}

#howItWorks-wrapper .content-boxes .heading .image {
    display: block;
    position: relative;
}

.image {
    margin-left: -7.5rem;
    margin-top: -1rem;
    margin-bottom: 1rem;
}


#howItWorks-wrapper .content-boxes .content-wrapper-boxed {
    overflow: visible;
    padding: 50px 30px 35px;
    text-align: left;
    hyphens: none;
    /* min-height: 263px;  */
    height: auto;
}

#howItWorks-wrapper .content-boxes .heading {
    overflow: visible;
    position: relative;
    text-align: justify;
    hyphens: auto;
}

#howItWorks-wrapper .content-boxes .heading h2 {
    color: #000000;
    margin-bottom: 15px;
    text-align: justify;
    hyphens: auto;
}

#howItWorks-wrapper .content-container {
    color: #000000;
}

.content-container p {
    font-weight: 500;
    margin-top: -0.75rem;
}

@media only screen and (max-width: 998.97px) {

    #howItWorks-wrapper .content-boxes,
    #howItWorks-wrapper .content-boxes .content-wrapper-boxed {
        min-height: 290px;
    }

    #howItWorks-wrapper .layout-column {
        min-height: unset;
    }
}

@media only screen and (max-width: 768.97px) {

    #howItWorks-wrapper .content-boxes,
    #howItWorks-wrapper .content-boxes .content-wrapper-boxed {
        min-height: 150px;
    }
}

@media only screen and (min-width : 992px) {
    .howItWorksCol.col-lg-3.col-md-6.col-sm-12 .layout-column .content-boxes {
        /* height: max-content !important; */
        /* min-height: 310px !important; */
    }

    .howItWorksCol.col-lg-3.col-md-6.col-sm-12 .layout-column {
        min-height: max-content !important;
    }
}

#howItWorks-wrapper .content-boxes .content-wrapper-boxed {
    padding: 30px 30px;
}


@media only screen and (min-width : 992px) {
    .howItWorksCol .layout-column .content-boxes {
        height: max-content !important;
        min-height: max-content !important;
        max-height: max-content !important;
    }
}

@media only screen and (min-width : 768px) and (max-width : 991.7px) {

    #howItWorks-wrapper .content-boxes,
    #howItWorks-wrapper .content-boxes .content-wrapper-boxed {
        min-height: 270px;
    }

    .image {
        margin-left: -12.5rem !important;
    }
}

@media only screen and (min-width : 991px) and (max-width : 2560px) {
    #howItWorks-wrapper .content-boxes .content-wrapper-boxed {
        min-height: 365px;
    }
}

.newchangesneededforbutton {
    margin-bottom: 2rem;
}

.applynowbutton {
    width: 60%;
}

@media(min-width: 992px)and (max-width:1199.7px) {
    #howItWorks-wrapper .content-boxes .content-wrapper-boxed {
        min-height: 460px !important;
    }

    .applynowbutton {
        width: 75% !important;
        margin-top: 4rem !important;
    }

    .image {
        margin-left: -5.5rem !important;
    }
}

@media(max-width:767px) {
    .image {
        margin-left: -14rem !important;
    }
}

@media(min-width: 320px)and (max-width:374.7px) {
    .image {
        margin-left: -10rem !important;
    }
}

@media(min-width: 768px)and (max-width:991.7px) {
    .newchangesneededforbutton {
        margin-bottom: 3rem !important;
    }
}


@media only screen and (min-width: 2560px) {
    .howItWorksCol.col-lg-3.col-md-6.col-sm-12 .layout-column .content-boxes {
        min-height: 370px !important;
    }
}