.pageContainer {
    background: #F6FDFF;
    margin-top: 7vh !important;
    /* margin-bottom: -7vh; */
}

.Sitemapnofoundpage {
    width: 1300px;
    display: block;
    margin-right: auto;
    margin-left: auto;
}

.Sitemapnofoundpagediv {
    margin-top: -84px;
}

.section_terms {
    /*  */
    padding: 20px;
    border-radius: 11px;
    margin-bottom: 10px;
}

.section_terms i {
    margin-right: 4px;
    margin-left: 4px;
}

.terms_header {
    height: 3px;
    background: #00aae4;
    width: 55px;
}


.site_map_section ul li.content_head {
    color: #083F88;
    font-size: 30px;
    font-weight: bold;
    margin-bottom: 10px;
}

.site_map_section {
    margin-top: 20px;
}

.site_map_section ul li {
    list-style: none;
    color: #4e6582;
    font-size: 14px;
}

.site_map_section ul li a {
    color: #4e6582;
    font-size: 14px;
}

.site_map_section ul li a {
    text-decoration: none;
}

.site_map_section ul li.content_section {
    color: #083F88;
    font-size: 21px !important;
    font-weight: bold;
}

.subheader {
    font-size: 16px !important;
    font-weight: 600 !important;

}

/* 

        .pageContainer{
            margin-top:84px;
           
        } */
.card_style {
    box-shadow: 0 2px 4px -1px rgba(0, 0, 0, .2), 0 4px 5px 0 rgba(0, 0, 0, .14), 0 1px 10px 0 rgba(0, 0, 0, .12);
    margin-bottom: 20px;
    padding: 10px;
    /* background-image: url('/images/Group+246%402x.png'); */

    background-color: #e3e7ea;
}

.card_style h5 {
    /* text-align:center; */
    padding: 10px;
}

.card_style ul li {
    list-style-type: none;
    /* text-decoration: underline; */
    color: blue;
    padding: 5px;
}

.card_style ul li:hover {
    cursor: pointer;
}

.pageContainer {
    margin-top: 84px;

}

.card_style {
    box-shadow: 0 2px 4px -1px rgba(0, 0, 0, .2), 0 4px 5px 0 rgba(0, 0, 0, .14), 0 1px 10px 0 rgba(0, 0, 0, .12);
    margin-bottom: 20px;
    padding: 10px;
    /* background-image: url('/images/Group+246%402x.png'); */

    background-color: #e3e7ea;
}

.card_style h5 {
    /* text-align:center; */
    padding: 10px;
}

.card_style ul li {
    list-style-type: none;
    /* text-decoration: underline; */
    color: blue;
    padding: 5px;
}

.card_style ul li:hover {
    cursor: pointer;
}

.hero_image {
    background-image: linear-gradient(rgba(12, 60, 118, 0.6), rgba(12, 60, 118, 0.7)), url("https://resources.swmc.com/swmc-images/sitemap_banner.jpg");
    height: 50vh;
    background-position: right;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    margin: 7% 2% 2%;
    border-radius: 10px;
}

@media screen and (width < 768px) {
    .pageContainer {
        width: auto;
        margin-bottom: -2vh;
    }

    .Sitemapnofoundpage {
        width: auto;
    }

    .Sitemapnofoundpagediv {
        margin-top: 0px;
    }
}


@media(min-width: 1200px)and (max-width:1400px) {
    .Sitemapnofoundpage {
        width: 1250px;
        margin-top:1rem;
    }
  }