/*
 * Copyright© Celligence International, LLC - 2023
 * 
 * This software program is protected by copyright laws and is the property of
 * Celligence International, LLC (Celligence). Any unauthorized reproduction,
 * distribution, modification, or public display of this program, without the
 * explicit permission of Celligence is strictly prohibited. If you have gained
 * access to this program without the expressed approval of Celligence report
 * the incident to legal@celligence.com.
 * 
 */
 .userWayContainer {
    margin-top: 15px ;
    margin-left: 5px ;
  }
  .userWayBackGroundBody {
    cursor: pointer;
    background:transparent!important;
    border: none;
    border-radius: 50% !important;
    padding: 3px;
    display: inline-block;
    width: 50px;
    height: 50px;
    min-width: 50px;
    min-height: 50px;
    max-width: 50px;
    max-height: 50px;
    /* box-sizing: content-box !important; */
  }
  
  .userWayBackGroundBody:hover {
      transition: transform .4s!important;
      transform: scale(1.25);
  }

  @media only screen and (min-width: 768px) and (max-width: 991.7px) {
    .userWayContainer {
      margin-top: 5px !important;
    }
  }
  
  @media only screen and (min-width: 320px) and (max-width: 350.7px) {
    .userWayContainer {
      margin-top: 1.5rem !important;
      margin-left: 16rem !important;
    }
    .main_logo{
      margin-left: -1rem !important;
      margin-top: 1rem;
    }
  }

  @media only screen and (min-width: 351px) and (max-width: 375.7px) {
    .userWayContainer {
      margin-top: 1.5rem !important;
      margin-left: 18rem !important;
    }
    .main_logo{
      margin-left: -1rem !important;
      margin-top: 1rem;
    }
  }
  
  @media only screen and (min-width: 376px) and (max-width: 400.7px) {
    .userWayContainer {
      margin-top: 1.5rem !important;
      margin-left: 20rem !important;
      width: 45px!important ;
    }
  }
  
  @media only screen and (min-width: 991px) {
    .userWayContainer {
     margin-top: 2px !important;
     margin-left: 2rem !important;
    }
  }

  

  @media only screen and (min-width: 401px) and (max-width: 450.8px) {
    .userWayContainer {
     margin-top: 1.5rem !important;
     margin-left: 21rem !important;
    }
  }

  @media only screen and (min-width: 451px) and (max-width: 500.8px) {
    .userWayContainer {
     margin-top: 1.5rem !important;
     margin-left: 24rem !important;
    }
  }

  @media only screen and (min-width: 501px) and (max-width: 575.8px) {
    .userWayContainer {
     margin-top: 1.5rem !important;
     margin-left: 26rem !important;
    }
  }

  @media only screen and (min-width: 576px) and (max-width: 767.8px) {
    .userWayContainer {
     margin-top: 1.5rem !important;
     margin-left: 30rem !important;
    }
  }
 
  .accessibilityimage {
    width: 100%;
  }

 
  