  .ratesButton a {
      color: #fff !important;
  }

  .homepageNav {
      z-index: 1050;
      box-shadow: none;
      font-size: 15px;
      background-color: white !important;
      /* transition: margin 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms, width 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms; */
      height: auto;
  }



  .homepageNav>div>div>a {
      color: white !important
  }

  .homepageNav>div>div>div a {
      /* transition: all 200ms cubic-bezier(0.455, 0.03, 0.515, 0.955); */
  }

  .homepageNav>div a {
      font-family: 'Poppins';
      text-decoration: none;
      text-transform: capitalize;
      font-size: 15px;
      font-weight: 100;
      letter-spacing: 0.4px;
      color: black;
      /* transition: all 200ms cubic-bezier(0.455, 0.03, 0.515, 0.955); */
  }

  .homepageNav>div>div>div>div>a {
      color: rgb(53, 53, 56);
      /* transition: all 200ms cubic-bezier(0.455, 0.03, 0.515, 0.955); */
  }


  .ratesButton {
      background-color: #3074b7;
      color: #fff !important;
      border-radius: 40px;
      padding: 10px 20px !important;
  }

  .main_logo {
      width: 240px;
      /* height:45px; */
  }

  .trust_logo {
      height: 50px;
      width: 55px;
  }

  .normalNav {
      z-index: 1200;
      box-shadow: none;
      font-size: 15px;
      background-color: white !important;
      /* transition: margin 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms, width 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;    */
  }

  @media (min-width: 768px) and (max-width: 991.7px) {
      .normalNav {
          margin-left: -3rem !important;
      }
  }

  .normalNav>div>div>div a {
      /* transition: all 200ms cubic-bezier(0.455, 0.03, 0.515, 0.955); */
  }

  .normalNav>div a {
      font-family: 'Poppins';
      text-decoration: none;
      text-transform: capitalize;
      font-size: 15px;
      font-weight: 100;
      letter-spacing: 0.4px;
      color: black;
      /* transition: all 200ms cubic-bezier(0.455, 0.03, 0.515, 0.955); */
  }

  .normalNav>div>div>div>div>a {
      color: rgb(53, 53, 56) !important;
  }

  

  @media(max-width:767px) {
      .homepageNav {
          margin-bottom: 0;
          /* transition: all 300ms cubic-bezier(0.455, 0.03, 0.515, 0.955); */
          z-index: 1200;
      }

      .homepageNav>div>div>div a {
          color: black !important;
          text-align: left;
          margin-left: 20px;
          /* transition: all 200ms cubic-bezier(0.455, 0.03, 0.515, 0.955); */
      }


  }

  @media (min-width: 320px) and (max-width: 767.7px) {
      .main_logo {
          width: 180px;
          
      }
  }

  @media (min-width: 768px) and (max-width: 992px) {
      .main_logo {
          width: 250px;
         
      }
  }

  @media (min-width: 320px) and (max-width: 389.7px) {
    .main_logo_div {
     margin-right: 2.5rem;
    }
   }

   @media (min-width: 390px) and (max-width: 449.7px) {
    .main_logo_div {
     margin-right: 3.5rem;
    }
   }

   @media (min-width: 450px) and (max-width: 574.7px) {
    .main_logo_div {
     margin-right: 6rem;
    }
   }

   @media (min-width: 575px) and (max-width: 767.7px) {
    .main_logo_div {
     margin-right: 8rem;
    }
   }

  