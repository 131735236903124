.bannerButton{
    font-weight: 100;
    font-family: "Graphik Medium";
    letter-spacing: 0.3px;
    background-color: #3074b7 ;
    color: #fff ;
    padding: 14px 33px !important;
    border-radius: 30px !important;
    border: 2px solid #3074b7 !important;
    text-decoration: none !important;
    display: inline-block;
    transition: all .3s;
    color: #ffffff;
    margin-right: 20px;
    width: 250px;
    margin-bottom: 15px;
    text-align: center;
    cursor: pointer;
  }