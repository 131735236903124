  .calculators_container {
    margin-bottom: 45px;
  }

  .calculators_container a {
    text-decoration: none;
  }

  .calculators_container h4 {
    color: #083f88;
    font-weight: 900;
    font-size: 27px;
  }

  .calculators_container h5 {
    color: #083f88;
    font-weight: 900;
    font-size: 22px !important;
  }

  .calculators_container p {
    font-size: 20px;
    font-family: "Poppins";
    color: #083F88;
    font-weight: 500;
    margin-bottom: 3rem;
  }

  .title h1 {
    color: #FFFFFF;
    font-size: 31px;
    font-family: "Poppins";
    margin-bottom: 5rem;
    margin-top: -0.75rem;
    text-align: center;
    font-weight: 500;
    line-height: 1.2;
  }

  @media(min-width: 768px)and (max-width:991.7px) {
    .title h1 {
      margin-bottom: 3rem !important;
      font-size: 28px;
      margin-top: -1.5rem;
    }
  }

  @media(max-width:767.7px) {
    .title h1 {
      font-size: 27px;
      margin-bottom: 2rem;
      margin-top: -10rem;
    }

    .title h4 {
      font-size: 29px;
    }

    .title h5 {
      /* text-align: justify !important; */
    }
  }

  @media(min-width: 320px)and (max-width:374.7px) {
    .title h1 {
        padding-top:26px !important;
        font-size: 24px !important;
    }

    .title h4 {
      font-size: 26px !important;
    }
}


@media(min-width: 410px)and (max-width:449.7px) {
  .title h1 {
    margin-top: -10.75rem !important;
  }
}


