.aboutWrapper {
  margin-top: 65px;
}

.aboutHeading {
  font-size: 32px;
  font-family: "Poppins";
  z-index: 2;
  font-weight: 900;
  color: #FFFFFF;
  text-align: center;
  margin-bottom: 20px;
  background-color: #00AAE4;
}

.aboutHeader {
  font-size: 32px;
  font-family: "Poppins";
  font-weight: 900;
  color: #083F88;
  text-align: center;
  margin-top: 30px;
  margin-bottom: 20px;
}

.aboutPara {
  font-size: 19px;
  padding: 10px 15px;
  font-family: "Poppins";
  color: #083F88;
  text-align: center;
  font-weight: 500;
}

.whychooserow {
  background-color: #FFFFFF;
  box-shadow: 0px 0px 25px 0px grey;
  border-radius: 1rem !important;
  position: relative;
}

.whychooserow::before {
  content: "ABOUT US";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 60px;
  background-color: #00AAE4;
  border-radius: 1rem 1rem 0 0;

  display: flex;
  justify-content: center;
  align-items: center;

  font-size: 36px;
  font-family: "Poppins";
  z-index: 2;
  font-weight: 500;
  color: #FFFFFF;
  text-align: center;
}

.bannerButton {
  font-weight: 100;
  font-family: "Poppins";
  letter-spacing: 0.3px;
  background-color: #3074b7;
  color: #fff;
  padding: 14px 33px !important;
  border-radius: 30px !important;
  border: 2px solid #3074b7 !important;
  text-decoration: none !important;
  display: inline-block;
  transition: all .3s;
  color: #ffffff;
  margin-right: 20px;
  width: 250px;
  margin-bottom: 15px;
  text-align: center;
  cursor: pointer;
}

.whiteBannerButton {
  background-color: white !important;
  color: #3074b7 !important;
}

.aboutFeatureCard {
  margin-bottom: 50px;
  max-height: 250px !important;
  position: relative;
  margin: 0rem -0.25rem;
}

.overlay {
  height: 100%;
  left: 0;
  top: 0;
  padding: 15px 25px 0;
  position: absolute;
  width: 100%;
  transition: all .3s ease-in-out 0s;
  color: #ffffff;
  display: none;
}

.textCenter {
  text-align: center !important;
  ;
}

.overlay h3 {
  color: #ffffff;
  margin-bottom: 20px;
  font-size: 32px;
  font-family: "Poppins";
  font-weight: 100;
}

.overlay p {
  font-size: 16px;
  font-family: "Poppins";
}

.overlayButton {
  box-sizing: border-box;
  position: relative;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: pointer;
  outline: none;
  border: none;
  -webkit-tap-highlight-color: transparent;
  display: inline-block;
  white-space: nowrap;
  text-decoration: none;
  vertical-align: baseline;
  text-align: center;
  margin: 0;
  min-width: 64px;
  line-height: 36px;
  padding: 0 16px;
  border-radius: 4px;
  overflow: visible;
  box-shadow: 0px 0px 0px 0px rgb(0 0 0 / 20%), 0px 0px 0px 0px rgb(0 0 0 / 14%), 0px 0px 0px 0px rgb(0 0 0 / 12%);
  background-color: #3074b7;
  color: white;
}

.titleBar {
  bottom: 0;
  position: absolute;
  width: 100%;
  transition: all .3s ease-in-out 0s;
  color: #ffffff;
  font-size: 22px;
  justify-content: left;
  padding: 8px 15px;
  background-color: rgba(5, 77, 154, .9)
}

.flexDisp {
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center
}

.aboutFeatureCard:hover .overlay {
  display: block;
  background-color: rgba(5, 77, 154, .9);
  transition: all .6s ease-in-out 0s;
}

.aboutFeatureCard:hover .titleBar {
  display: none;
  transition: all .3s ease-in-out 0s;
  background-color: rgba(5, 77, 154, .9)
}

@media(min-width:600px)and (max-width:1025px) {
  .videoRow {
    flex-direction: column-reverse;
  }

  .videoRow>div {
    width: 100%;
  }

  .videoRow>div :nth-child(1) {
    margin-top: 30px;
  }

  .titleBar {
    font-size: 16px;
  }
}

@media(min-width:576px)and (max-width:768px) {
  .overlay {
    padding: 10px;
  }

  .overlay h3 {
    color: #ffffff;
    margin-bottom: 20px;
    font-size: 18px;
    font-family: "Poppins";
    font-weight: 100;
  }

  .overlay p {
    font-size: 14px;
    font-family: "Poppins";
  }
}

@media(min-width:768px)and (max-width:1024px) {
  .overlay {
    padding: 15px 10px;
  }

  .overlay h3 {
    color: #ffffff;
    margin-bottom: 4px;
    font-size: 18px;
    font-family: "Poppins";
    font-weight: 100;
  }

  .overlay p {
    font-size: 11px;
    font-family: "Poppins";
  }
}

.aboutbannerimage {
  width: 100%;
}

.imageContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: -18rem;
  margin-bottom: 4rem;
  margin-left: 7rem;
}

.applynowaboutusimage {
  width: 60%;
}

.talktousimage {
  width: 60%;
}

.aboutusdiv {
  background-image: url('https://staging-cdn.swmc.com/lowrates/aboutusbgimage.webp');
  background-repeat: no-repeat;
  background-size: 100% 60%;
  position: relative;
}

.aboutuscontainer {
  width: 70%;
}

.loanhowitworksDiv {
  background-image: url('https://staging-cdn.swmc.com/lowrates/howitworksbgimage.webp');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  position: relative;
}

.ourprocessdiv {
  background-image: url('https://staging-cdn.swmc.com/lowrates/aboutusdesktopbg.webp');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  position: relative;
  margin-top: -3rem;
}

.loanhowitworksContainer {
  margin-top: 3rem;
  width: 1150px;
}

.valuemissioncontainer {
  margin-top: 3rem;
  width: 70%;
}

.valuemissioncontainer p {
  font-weight: 500;
  font-size: 18px;
  font-family: "Poppins";
}

.valuemissioncontainer li {
  font-weight: 500;
  font-size: 18px;
  font-family: "Poppins";
}

.valuemissionimage {
  width: 85%;
  margin-top: 2rem;
}

.ourprocesscontainer {
  margin-top: 5rem;
  width: 70%;
}

.ourprocesscontainer p {
  font-weight: 500;
  font-size: 18px;
  font-family: "Poppins";
  text-align: justify;
}

.progressbarcolumn {
  margin-top: 1rem;
}

/* styles.css */
.progress_levels {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.box_title {
  color: #000000;
  font-weight: 600;
  font-family: "Poppins";
}

.progress_box {
  width: 100%;
  margin-bottom: 20px;
}

.bar {
  width: 60%;
  height: 20px;
  background-color: #c4c4c4;
}

.bar_fill {
  height: 100%;
  background-color: #083f88;
}

.percent {
  top: 50%;
  left: 50%;
  transform: translate(47%, -100%);
  font-size: 16px;
  font-weight: 700;
  color: #000000;
  text-align: center;
  line-height: 20px;
}

.lowratesactivitydiv {
  background-color: #083F88;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  height: auto;
  margin-top: 3rem;
  margin-bottom: 3rem;
  width: 80%;
  margin-left: auto;
  border-top-left-radius: 2rem;
  border-bottom-left-radius: 2rem;
}

.lowratesactivityrow {
  margin-left: 2rem;
}

.lowratesactivityfaq {
  margin-top: 3rem;
  margin-bottom: 3rem;
}

.lowratesactivityfaq h4 {
  text-align: justify;
  color: #FFFFFF;
  margin-bottom: 2rem;
}

.lowratesactivityfaq h5 {
  text-align: center;
  color: #FFFFFF;
  margin-bottom: 1rem;
}

.lowratesactivityfaqli {
  text-align: justify;
  color: #FFFFFF;
  font-size: 18px;
  margin-bottom: 0.5rem;
  text-decoration: none;
}

.lowratesactivityfaqanswer {
  font-size: 13px;
  font-family: "Poppins";
}

/* .lowratesactivityvideo{
  width:100%;
  height: 350px;
} */

@media(min-width: 992px)and (max-width:1199.7px) {
  .imageContainer {
    margin-top: -13rem;
    margin-bottom: 0rem;
  }

  .aboutuscontainer {
    width: 80%;
  }

  .loanhowitworksContainer {
    width: 90%;
  }

  .valuemissioncontainer {
    width: 90%;
  }

  .ourprocesscontainer {
    width: 90%;
  }

  .lowratesactivitydiv {
    width: 90%;
  }

  .lowratesactivityfaqli {
    font-size: 13px;
    margin-bottom: 0.75rem;
  }

  .lowratesactivityfaqanswer {
    font-size: 11px;
  }
}

@media(min-width: 768px)and (max-width:991.7px) {
  .imageContainer {
    margin-top: -12rem;
    margin-bottom: 0rem;
  }

  .aboutuscontainer {
    width: 80%;
  }

  .loanhowitworksContainer {
    width: 90%;
  }

  .valuemissioncontainer {
    width: 90%;
  }

  .ourprocesscontainer {
    width: 90%;
  }

  .valuemissioncontainer p {
    font-size: 14px;
  }

  .valuemissioncontainer li {
    font-size: 14px;
  }

  .valuemissionimage {
    width: 100%;
    margin-top: 2rem;
  }

  .box_title {
    font-size: 12px !important;
  }

  .lowratesactivityfaq h4 {
    font-size: 18px;
  }

  .lowratesactivityfaq h5 {
    font-size: 14px !important;
    margin-left: 5rem;
  }

  .lowratesactivityfaqli {
    font-size: 13px;
    margin-bottom: 0.75rem;
  }

  .lowratesactivityfaqanswer {
    font-size: 12px;
  }

  .lowratesactivitydiv {
    width: 100%;
    border-top-left-radius: 0rem;
    border-bottom-left-radius: 0rem;
    padding-top: 0.5rem;
    margin-top: 0rem;
    margin-bottom: 2rem;
  }

  .lowratesactivityrow {
    margin-left: 1rem;
  }

  .lowratesactivityvideo {
    width: 150%;
  }

  .aboutusdiv {
    background-image: none;
  }

  .loanhowitworksDiv {
    background-image: none;
    background-color: #00AAE4;
  }

  .ourprocessdiv {
    background-image: none;
    background-color: rgba(0, 170, 228, 0.1);
  }

}

@media (min-width:767.7px) {
  .aboutbannerimagemobile {
    display: none;
  }
}

@media (max-width:767.7px) {
  .aboutbannerimage {
    display: none;
  }

  .aboutbannerimagemobile {
    width: 100%;
  }

  .aboutusdiv {
    background-image: none;
  }

  .loanhowitworksDiv {
    background-image: none !important;
    background-color: #00AAE4 !important;
  }

  .ourprocessdiv {
    background-image: none;
    background-color: rgba(0, 170, 228, 0.1);
  }

  .lowratesactivitydiv {
    width: 100%;
    border-top-left-radius: 0rem;
    border-bottom-left-radius: 0rem;
    padding-top: 0.5rem;
    margin-top: 0rem;
    margin-bottom: 2rem;
  }

  .aboutuscontainer {
    width: auto;
  }

  .loanhowitworksContainer {
    width: auto;
  }

  .valuemissioncontainer {
    width: auto;
  }

  .ourprocesscontainer {
    width: auto;
  }

  .applynowaboutusimage {
    width: 88%;
  }

  .talktousimage {
    width: 79%;
    margin-top: -2rem;
  }

  .imageContainer {
    display: block;
    margin-top: -18rem;
    margin-bottom: 3rem;
    margin-left: 3rem !important;
  }

  .whychooserow::before {
    border-radius: 0rem;
  }

  .aboutHeader {
    font-size: 25px;
    margin-top: 44px;
  }

  .aboutPara {
    text-align: justify;
    hyphens: none;
  }

  .whychooserow {
    border-radius: 0rem !important;
  }

  .aboutFeatureCard {
    margin-bottom: 50px;
    max-height: auto !important;
    position: relative;
    margin: 1rem 0.75rem !important;
  }

  .lowratesactivityrow {
    margin-left: 0rem;
  }

  .lowratesyoutubevideo {
    margin-top: -5rem !important;
  }

  .valuemissionimage {
    width: 95%;
    margin-top: 2rem;
  }

  .bar {
    width: 100%;
    height: 20px;
    background-color: #c4c4c4;
  }

  .percent {
    margin-left: 90%;
    transform: translate(0%, -100%);
  }

  .valuemissionheadingmobile {
    display: flex!important;
    justify-content: center;
  }

  .lowratesactivityvideo {
    width: 97%;
  }

  .valuemissioncontainer {
    margin-top: 2rem !important;
  }

  .lowratesactivityfaqli {
    text-align: justify;
    margin-left: -1rem;
    font-size: 17px;
    margin-bottom: 0.5rem;
    text-decoration: none;
  }

  .valuemissioncontainer p {
    text-align: justify;
  }

}


@media(min-width: 1200px)and (max-width:1399.7px) {
  .overlay h3 {
    font-size: 20px !important;
  }

  .overlay p {
    font-size: 13px !important;
  }
}