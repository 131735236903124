footer {
  position: relative;
  padding: 10px 50px;
  background-color: #083f88;
  background-position: center bottom;
  background-repeat: no-repeat;
}

footer .bottomlinks {
  color: #fff;
  font-size: 28px;
}

footer .bottomlinks a:hover {
  text-decoration: none;
  color: rgba(255, 255, 255, 0.767);
}

footer .v-line {
  height: 80%;
  padding-left: 0.2rem;
  padding-right: 0.2rem;
  border-left: 2px solid #fff;
}

footer p {
  font-size: 13px;
  /* color: #1f2127; */
  line-height: 1.7em;
}

footer a {
  text-decoration: none;
  /* cursor: pointer; */
  color: #fff;
}

footer .footerYear {
  font-size: 18px;
  margin-bottom: 0;
}



footer #callMeFooterLine {
  display: block;
  height: 4px;
  margin: 12px 0 11px 0;
  background: linear-gradient(to left,
      #ffffff00 15%,
      #73d0f0 33%,
      #05ace5 50%,
      #73d0f0 66%,
      #ffffff00 85%);
}

.bottomlink a,
.bottomlink p {
  color: #fff;
  margin-bottom: 0;
  text-decoration: none;
}

@media only screen and (max-width: 767px) {
  footer .bottomlinks a {
    font-size: 24px;
  }

  footer .footerYear {
    font-size: 16px;
  }

  footer p {
    font-size: 18px;
  }

  
}

@media only screen and (max-width: 599px) {
  footer .bottomlinks a {
    font-size: 22px;
  }

  footer .footerYear {
    font-size: 14px;
  }

  footer p {
    font-size: 16px;
  }
}

@media only screen and (max-width: 499px) {
  footer .bottomlinks a {
    font-size: 20px;
  }

  footer .footerYear {
    font-size: 12px;
  }

  footer p {
    font-size: 14px;
  }
}

@media (min-width: 768px) and (max-width: 992px) {
  .firstfootercolumn1 {
    margin-right: 3rem !important;
  }
  .firstfootercolumn2 {
    margin-right: 1.5rem !important;
  }

}

@media (min-width: 320px) and (max-width: 575.7px) {
  .ehologo {
    margin-bottom: 3rem !important;
  }
}


.footernewchnages {
  padding-bottom: 3rem !important;
  padding-top:1.5rem !important;
}

.footernewchnagesbottom {
  padding-bottom: 1.5rem !important;
  padding-top:1rem !important;
}

@media (min-width: 320px) and (max-width: 767.7px) {
.footernewchnages {
  padding-bottom: 1.5rem !important;
  padding-top:1rem !important;
}
.footernewchnagesbottom {
  padding-bottom: 0rem !important;
  padding-top: 2rem !important;
}
}

@media (min-width: 768px) and (max-width: 991.7px) {
  .footernewchnages {
    padding-bottom: 2.5rem !important;
    padding-top:1rem !important;
  }
  .footernewchnagesbottom {
    padding-bottom: 2.5rem !important;
    padding-top: 0rem !important;
  }
  }