.pageContainer {
    margin-top: 75px;

}

.hero_image {
    background-image: linear-gradient(rgba(12, 60, 118, 0.6), rgba(12, 60, 118, 0.7)), url("https://resources.swmc.com/swmc-images/sitemap_banner.jpg");
    height: 25rem;
    background-position: right;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    margin: 5% 2% 2%;
    border-radius: 10px;
}

.hero_image h1 {
    color: #fff;
    font-size: 100px;
    line-height: .9;
    z-index: 2;
    position: relative;
    font-family: 'Graphik Medium';
}

@media(min-width: 1200px)and (max-width: 1399.7px) {
    .hero_image {
        margin-top: 75px;
        height: 22rem;
    }
}

@media(min-width: 992px)and (max-width: 1199.7px) {
    .hero_image {
        margin-top: 75px;
        height: 20rem;
    }
}

@media(min-width: 768px)and (max-width:991.7px) {
    .hero_image {
        margin-top: 75px;
        height: 18rem;
    }
}


@media(max-width:767.8px) {

    .hero_image h1 {
        font-size: 60px;
    }

    .hero_image {
        margin-top: 75px;
        height: 20rem;
    }

    .hero_image>h1 {
        padding-top: 25% !important;
    }

    .pageContainer {
        margin-top: 4px;
    }
}