.pageContainer {
  width: 70%;
  margin-bottom: 20px;
}

.pageContainer p {
  font-size: 16px !important;
  text-align: justify;
}

.openingPositions {
  margin-bottom: 4%;
  box-shadow: 0px 5px 10px 5px rgba(0, 0, 0, 0.2);
}

.openingPositions h2 {
  margin-bottom: 2% !important;
}

.openingPositions::before {
  content: '';
  /* border-top: 3px solid #1d90cc; */
  width: 65px;
  position: relative;
}

.title h1 {
  color: #FFFFFF;
  font-size: 35px;
  font-family: "Poppins";
  margin-bottom: -0.75rem;
  margin-top: -4rem;
  padding-top: 12px;
  text-align: center;
  font-weight: 500;
  line-height: 1.2;
}


.loanprogrambannerimage {
  width: 100%;
  margin-top: 4%;
}

.faqimageontop {
  width: 100%;
  height: 60px;
}

.applynowbanner {
  display: block;
  margin-right: auto;
  margin-left: auto;
  margin-top: -10rem;
  margin-bottom: 5rem;
  width: 17.5%;
}

@media(min-width: 768px)and (max-width:991.7px) {

  .applynowbanner {
    margin-top: -5rem;
    margin-bottom: 2rem;
    width: 20%;
  }

  .pageContainer p {
    font-size: 12.5px !important;
  }

  .pageContainer {
    width: 95%;
  }
}

@media(min-width: 1200px)and (max-width:1399.7px) {
  .loanprogrambannerimage {
    width: 100%;
    margin-top: 6%;
  }

  .applynowbanner {
    margin-top: -8rem;
    margin-bottom: 4rem;
  }
}

@media(min-width: 992px)and (max-width:1199.7px) {
  .pageContainer {
    width: 85%;
  }

  .pageContainer p {
    font-size: 15.5px !important;
  }

  .loanprogrambannerimage {
    width: 100%;
    margin-top: 8%;
  }

  .applynowbanner {
    margin-top: -6rem;
    margin-bottom: 3rem;
  }
}

@media(min-width: 768px)and (max-width:991.7px) {
  .loanprogrambannerimage {
    width: 100%;
    margin-top: 9%;
  }

  .applynowbanner {
    margin-bottom: 2rem;
  }
}

@media (min-width:768px) {
  .loanprogrambannerimagemobile {
    display: none;
  }
}

@media (max-width:767.7px) {
  .title h1 {
    padding-top: 0;
    font-size: 26px;
    margin-top: -2.75rem;
    margin-bottom: 0rem;
  }

  .title h2 {
    font-size: 30px;
  }

  .pageContainer {
    width: 100%;
  }

  .openingPositions {
    margin-bottom: 5%;
  }


  .applynowbanner {
    width: 75% !important;
    margin-top: -8rem !important;
    margin-bottom: 2rem !important;
  }



  .loanprogrambannerimage {
    display: none;
  }

  .loanprogrambannerimagemobile {
    width: 100%;
    margin-top: 15% !important;
  }

}

@media (min-width:1970px) {
  .faqimageontop {  
    margin-top: -2rem !important;
  }
}

@media(min-width: 320px)and (max-width:374.7px) {
  .title h1 {  
    font-size: 22px !important;
  }
}