.title h1 {
    color: #FFFFFF;
    font-size: 37px;
    font-family: "Poppins";
    margin-bottom: 5rem;
    margin-top: -0.5rem;
    text-align: center;
    font-weight: 500;
    line-height: 1.2;
}

.tableHead {
    background-color: #00aae4 !important;
    color: #fff !important;
    font-size: 18px;
}

.tableHead1 {
    background-color: #00aae4 !important;
    color: #fff !important;
    text-align: center;
    font-size: 18px;
}

.table-bordered {
    width: 98%;
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.tableBody {
    border: 1px solid #808080;
    color: #083f88 !important;
}

.commonCell {
    width: 160px;
}

.maindocumentheading {
    background-color: #E5E5E5 !important;
    color: #083f88 !important;
    font-size: 19px;
    font-weight: 900;
}

.subdocumentheading {
    background-color: #00aae41a !important;
    font-size: 18px;
    font-weight: 900;
}

.tableli {
    font-size: 16px !important;
    margin-bottom: 0rem !important;
}

.tableBody tr {

    font-weight: 500;
}

@media(min-width: 992px)and (max-width:1199.7px) {
    .title h1 {
        margin-bottom: 3rem !important;
        margin-top: -1.5rem;
    }

    .table-bordered {
        width: 100% !important;
    }
}

@media(min-width: 768px)and (max-width:991.7px) {
    .title h1 {
        margin-bottom: 3rem !important;
        margin-top: -1.5rem;
    }
}

@media(max-width:767.7px) {
    .title h1 {
        font-size: 30px;
        margin-bottom: 2rem;
        margin-top: -10rem;
    }

    .commonCell {
        width: 105px !important;
    }

    .tableBody {
        font-size: 12.5px !important;
        font-weight: 600 !important;
    }
    
    .tableli {
        font-size: 12.5px !important;
        margin-bottom: 0rem !important;
    }
}

@media(min-width: 410px)and (max-width:475.7px) {
    .title h1 {
        margin-top: -10.5rem !important; 
        margin-bottom: 2rem !important; 
    }
}

@media(min-width: 320px)and (max-width:374.7px) {
    .title h1 {
        font-size: 28px !important;
        margin-bottom: 3rem !important;
        margin-top: -8rem !important; 
    }
}
