.title h1 {
  color: #FFFFFF;
  font-size: 40px;
  font-family: "Poppins";
  margin-bottom: 4rem;
  padding-top: 25px;
  text-align: center;
  font-weight: 500;
  line-height: 1.2;

}

.title h5 {
  font-size: 22px !important;
  font-weight: 600;
  font-family: "Poppins";
}

.title h2 {
  font-size: 28px;
  font-weight: 600;
  margin-bottom: 20px;
  font-family: "Poppins";
}



.disclaimerContainer {
  width: 950px;
  font-family: "Poppins";
}

.disclaimerContainer a {
  color: #0054a6;
}

.disclaimerTable td {
  text-align: justify !important;
}

.disclaimerTable>td {
  padding: 15px;
}


.tableHead>tr>th {
  padding: 15px;
  background-color: #3074b7;
  color: #ffffff;
}

.table-bordered tbody tr:nth-child(even) {
  background-color: #ffffff;
}

/* Apply styles to odd rows (background blue) */
.table-bordered tbody tr:nth-child(odd) {
  background-color: #00AAE4;
}

.tableHead>td {
  padding: 15px;
}

.strong {
  font-weight: 700;
  font-size: 20px;
}

p {
  font-size: 16px;
  font-family: "Poppins";
}

.loanprogrambannerimage {
  width: 100%;
  margin-top: 4%;
}

.applynowbanner {
  display: block;
  margin-right: auto;
  margin-left: auto;
  margin-top: -10rem;
  margin-bottom: 5rem;
  width: 15.5%;
}

.reficalculatorStyleDiv {
  background-image: url('https://staging-cdn.swmc.com/lowrates/calculatorsbackground.webp');
  background-repeat: no-repeat;
  background-size: 100% 2%;
}

@media(min-width: 992px)and (max-width:1199.7px) {
  .title h1 {
    font-size: 35px;
    padding-top: 36px;
    margin-bottom: 3rem;
  }
}

@media (min-width:768px) {
  .loanprogrambannerimagemobile {
    display: none;
  }
}

@media(min-width: 768px)and (max-width:991.7px) {
  .applynowbanner {
    margin-top: -5rem;
    margin-bottom: 2.5rem;
  }

  .reficalculatorStyleDiv {
    background-size: 100% 1.5%;
  }

  .title h1 {
    padding-top: 15px;
  }

  .loanprogrambannerimage {
    margin-top: 9%;
  }
}

@media (max-width:767.7px) {

  .disclaimerContainer {
    width: auto !important;
  }

  .title h1 {
    padding-top: 0;
    font-size: 35px;
    margin-top: -1rem;
    padding-top: 2.75rem;
    margin-bottom: 2rem;
  }

  .reficalculatorStyleDiv {
    background-image: url('https://staging-cdn.swmc.com/lowrates/calculatorsbackground.webp');
    background-repeat: no-repeat;
    background-size: 100% 1%;
  }

  .title h2 {
    font-size: 30px;
  }

  .pageContainer {
    margin-top: 0px;
  }


  .applynowbanner {
    width: 75% !important;
    margin-top: -8rem !important;
    margin-bottom: 2rem !important;
  }



  .loanprogrambannerimage {
    display: none;
  }

  .loanprogrambannerimagemobile {
    width: 100%;
    margin-top: 15% !important;
  }
}

@media(min-width: 1980px)and (max-width: 2560px) {
  .reficalculatorStyleDiv {
    margin-top: -1.6rem;
  }
}

@media(min-width: 992px)and (max-width: 1399.7px) {
  .applynowbanner {
    margin-top: -7rem !important;
    margin-bottom: 3rem !important;
  }
  .title h1 {
    font-size: 35px;
    padding-top: 25px;
  }
  
}

@media(min-width: 1400px)and (max-width: 1600px) {
  
  .title h1 {
    font-size: 35px;
    padding-top: 30px;
  }
  
}

