.table {
    /* width: 100%;
    max-width: 100%; */
    margin-bottom: 20px;
    background-color: transparent;
}

.table-bordered {
    border: 1px solid #ddd;
}

/* h1, h2, h3, h4, h5, h6 {
    font-family: 'Open Sans',Montserrat,sans-serif;
    font-weight: 400;
    color: #004ca4;
    margin: 0;
    background: 0 0;
    line-height: 1.5em;
} */
.h4, h4 {
    /* font-size: 24px; */
    /* color: #004ca4; */
}
