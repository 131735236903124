.bannerHeader {
  font-weight: 600;
  color: #3074b7;
  line-height: 48px;
  font-size: 44px;
  font-family: 'Graphik Bold' !important;
}

.blackBannerHeader {
  font-weight: 600;
  color: black;
  line-height: 48px;
  font-size: 44px;
  font-family: 'Graphik Bold' !important;
}

.bannerPara {
  font-family: 'Poppins' !important;
  font-size: 16px;
  line-height: 26px;
  font-weight: 400;
}

.bannerButton {
  font-weight: 100;
  font-family: "Graphik Medium";
  letter-spacing: 0.3px;
  background-color: #3074b7;
  color: #fff;
  padding: 14px 33px !important;
  border-radius: 30px !important;
  border: 2px solid #3074b7 !important;
  text-decoration: none !important;
  display: inline-block;
  transition: all .3s;
  color: #ffffff;
  margin-right: 20px;
  width: 250px;
  margin-bottom: 15px;
  text-align: center;
  cursor: pointer;
}

.whiteBannerButton {
  background-color: white !important;
  color: #3074b7 !important;
}

.parallaxDiv {
  background-color: #3f3c3c;
  padding-top: 70px;
  padding-bottom: 90px;
  text-align: center;
  min-height: 100vh;
  position: relative;
}

.parallaxDiv::after {
  background-image: url('https://prod-cdn.swmc.com/lowrates-development-angular/background-img.png');
  content: '';
  width: 33%;
  height: 236px;
  position: absolute;
  background-repeat: no-repeat;
  /* margin-top: -142px; */
  background-size: 100%;
  right: 0;
  bottom: 0;
}

.loanContainer {
  padding-top: 25px;
  text-align: center;
}

.loanDivText {
  display: inline-block;
  width: 45%;
  text-align: left;
}

.loanDivText h2 {
  font-size: 36px;
  font-family: "Graphik Bold";
  font-weight: 100;
  margin: 0;
}

.rateDiv {
  background-image: url('https://prod-cdn.swmc.com/lowrates-development-angular/man_03.png');
  background-repeat: no-repeat;
  background-position: bottom right;
  background-size: 60%;
  height: 497px;
}

.loanDivText p {
  font-size: 16px;
  font-family: "Poppins";
  line-height: 28px;
  padding-right: 146px;
}

.loanDivRate {
  background-color: #ffffff;
  text-align: center;
  border: 2px solid #444444;
  width: 250px;
  padding: 15px 30px;
  box-shadow: 2px 3px 19px -1px #80808061;
  border-radius: 4px;
}

.loanDivRate h5 {
  margin-top: 10px;
  line-height: 27px;
  margin-bottom: 5px;
  font-weight: 600;
  letter-spacing: 1px
}

.loanDivRate ul {
  padding: 0;
}

.loanDivRate li {
  list-style: none;
  background-color: #8fb4d8;
  margin-bottom: 10px;
  padding: 13px 0;
  color: #fff;
  border-radius: 3px;
  border: 2px solid #8fb4d8;
  transition: all .3s;
  cursor: pointer;
  font-size: 14px;
}

.loanDivRate li a {
  color: white;
  font-family: 'Poppins';
  text-decoration: none;
}

.headerTop {
  margin-top: 70px;
}

.formContainer {
  height: auto;
  margin-top: -0.5rem;
  margin-bottom: -1rem;
  position: relative;
  /* background-color: #000!important; */
}

.leftBGPattern {
  position: absolute;
  left: 0;
  bottom: 1%;
  height: 100%;
  z-index: -1;
}

.rightBGPattern {
  position: absolute;
  right: 0;
  bottom: 1%;
  height: 100%;
  z-index: -1;
}

.leftTopBlueDots {
  position: absolute;
  left: -1.5%;
  top: 5%;
  width: 3%;
  z-index: -1;
}

.leftBlueDots {
  position: absolute;
  left: 5%;
  top: 20%;
  width: 4%;
  z-index: -1;
}

.rightBlueDots {
  position: absolute;
  right: 2%;
  bottom: 15%;
  width: 4%;
  z-index: -1;
}

@media(max-width:1200px) {
  .parallaxDiv {
    min-height: auto;
  }
}

@media(max-width:767px) {
  .loanDivText {
    width: 100%;
  }

  .loanDivText p {
    padding-right: 0px;
  }

  .rateDiv {
    background-position: 150% 0%;
  }

  .headerTop {
    margin-top: 00px;
  }
}

@media(min-width:600px)and (max-width:768px) {
  .bannerHeader {

    font-size: 27px;
    font-family: 'Graphik Bold' !important;
  }

  .blackBannerHeader {

    font-size: 30px;
    font-family: 'Graphik Bold' !important;
  }

  .headerTop {
    margin-top: 00px;
  }

  .parallaxDiv {
    min-height: auto;
  }

  .loanDivText {
    display: inline-table;
  }

  .rateDiv {
    background-image: none;
  }

  .loanDivText p {
    padding-right: 0px;
  }
}

.phonetext {
  text-align: center;
  color: #00C2FF;
  margin-top: 1rem;
  font-weight: 600;
}

.phonetext a {
  color: #00C2FF;
}

.leftBannerDiv {
  margin-left: -2rem;
}

.rightBannerDiv {
  margin-left: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start !important;
}

.rightBannerDiv .rightImgBox {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-bottom: 1rem;
}

@media (min-width: 991px) {
  .rightBannerDiv {
    margin-bottom: 2rem;
  }
  .phonetext {
    margin-bottom: 1rem;
  }
  
  .phonetext a {
    color: #00C2FF;
    margin-bottom: 2rem;
  }
  
}

@media (max-width: 991px) {

  .rightBannerDiv {
    margin: 0 0 2rem 0;
    width: 60%;
    justify-content: flex-start !important;
  }
}

@media (min-width: 768px) and (max-width: 992px) {

  .rightBannerDiv {
    width: 50% !important;
    margin-bottom: 0;
    margin: 0rem 0 0 0;
  }

  .leftBannerDiv {
    margin-left: 0rem !important;
  }

  .rightBannerDiv {
    margin-left: 0rem !important;
    margin-bottom: 2rem !important;
  }

  .phonetext {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }
}



@media (max-width: 550px) {
  .rightBannerDiv .rightImgBox {
    flex-direction: column;
    align-items: center;
  }

  .rightBannerDiv p {
    margin-bottom: 20px !important;
  }
}

@media (min-width: 2100px) {
  .rightBannerDiv {
    margin: -5rem 0 0 0;
  }
}



@media (min-width: 1200px) and (max-width: 1400px) {
  .rightBannerDiv {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin: -0.5rem 0 0 0;
  }
}

@media (min-width: 2000px) and (max-width: 2560px) {
  .rightBannerDiv {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin: 0 0 0 0;
    margin-left: 2rem;
  }

}

@media (min-width: 991px) and (max-width: 1200px) {
  .leftBannerDiv {
    margin-left: 0rem !important;
  }

  .rightBannerDiv {
    margin-left: 0rem !important;
  }
}

@media (min-width: 320px) and (max-width: 767.7px) {
  .formContainer {
    height: auto;
  }

  .leftBannerDiv {
    margin-left: 0rem !important;
  }

  .rightBannerDiv {
    margin-left: 0rem !important;
    width: 100% !important;
  }

}

@media (min-width: 768px) {
  .imagewrappermobile {
    display: none;
  }
}

@media (max-width: 767.8px) {
  .imagewrappermobile {
    height: 100px;
    margin: -8rem 0;
    width: 100%;
    margin-left: 0rem;
  }
}

@media (min-width: 425px) and (max-width: 500px) {
  .imagewrappermobile {
    margin: 3rem 0;
    margin-bottom: -8rem !important;
  }
}

@media (min-width: 501px) and (max-width: 575px) {
  .imagewrappermobile {
    margin: 3rem 0;
    margin-bottom: -8rem !important;
  }
}

@media (min-width: 576px) and (max-width: 767px) {
  .imagewrappermobile {
    margin: 3rem 0;
    margin-bottom: -8rem !important;
  }

}

@media (min-width: 360px) and (max-width: 449.7px) {
  .imagewrappermobile {
    margin-top: 3rem !important;
  }

  .phonetext {
    margin-top: 2rem !important;
  }
}

@media (min-width: 320px) and (max-width: 349.7px) {
  .imagewrappermobile {
    margin-top: 4rem !important;
  }

  .phonetext {
    margin-top: 2rem !important;
  }
}

@media (min-width: 768px) and (max-width: 991.7px) {
  .formContainer {
    height: auto;
  }
}

@media (min-width: 992px) and (max-width: 1100px) {
  .formContainer {
    height: auto;
  }
}

 @media (min-width: 2000px) and (max-width: 2560px) {
  .rightBannerDiv .rightImgBox {
    margin-bottom: 2rem !important;
  }
}


@media (min-width: 992px) and (max-width:1350.7px) {
  .rightBannerDiv .rightImgBox {
    margin-bottom: 2rem;
  }
  .phonetext {
    margin-bottom: 2rem;
  }
}

.ratesatagnewchanges {
    color: #ffffff;
}

.ratesatagnewchanges:hover {
  color: #00aae4;
}