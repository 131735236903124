
.terms_of_use_container{
    line-height: 1.6em;
    margin-top: 100px;
    margin-bottom: 45px;
}

.terms_of_use_container a{
  text-decoration: none;
}

.terms_of_use_container p{
    font-family: "Poppins";
}

.title h1{
    font-size: 44px;
    font-family: "Graphik Bold";
    margin-bottom: 35px;
    text-align: center;
    font-weight: 500;
    line-height: 1.2;
}

.strong_caps_text{
    font-weight: 600;
    text-transform: uppercase;
    font-family: "Graphik Medium";
}