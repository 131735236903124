.pageContainer {
    margin-top: 84px;

}

.card_style {
    box-shadow: 0 2px 4px -1px rgba(0, 0, 0, .2), 0 4px 5px 0 rgba(0, 0, 0, .14), 0 1px 10px 0 rgba(0, 0, 0, .12);
    margin-bottom: 20px;
    padding: 10px;
    background-image: url('https://resources.swmc.com/swmc-images/images/Group+246%402x.png');

    background-color: #e3e7ea;
}

.card_style h5 {
    padding: 10px;
}

.card_style ul li {
    list-style-type: none;
    color: blue;
    padding: 5px;
}

.card_style ul li:hover {
    cursor: pointer;
}

.pageContainer {
    margin-top: 84px;

}

.card_style {
    box-shadow: 0 2px 4px -1px rgba(0, 0, 0, .2), 0 4px 5px 0 rgba(0, 0, 0, .14), 0 1px 10px 0 rgba(0, 0, 0, .12);
    margin-bottom: 20px;
    padding: 10px;
    background-image: url('https://resources.swmc.com/swmc-images/images/Group+246%402x.png');

    background-color: #e3e7ea;
}

.card_style h5 {
    padding: 10px;
}

.card_style ul li {
    list-style-type: none;
    color: blue;
    padding: 5px;
}

.card_style ul li:hover {
    cursor: pointer;
}

.hero_image {
    background-image: linear-gradient(rgba(12, 60, 118, 0.6), rgba(12, 60, 118, 0.7)), url("https://resources.swmc.com/swmc-images/sitemap_banner.jpg");
    height: 50vh;
    background-position: right;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    margin: 7% 2% 2%;
    border-radius: 10px;
}

.hero_image h1 {
    color: #fff;
    font-size: 120px;
    line-height: .9;
    z-index: 2;
    position: relative;
    font-family: 'Lucky Fellas';
}

.site_map_section ul li.content_head {
    color: #083F88;
    font-size: 30px;
    font-weight: bold;
    margin-bottom: 10px;
}

.content_head a {
    color: #083F88 !important;
    font-size: 30px !important;
    font-weight: bold !important;
    margin-bottom: 10px !important;
}

.site_map_section {
    margin-top: 20px;
}

.site_map_section ul li {
    list-style: none;
    color: #4e6582;
    font-size: 14px;
}

.site_map_section ul li a {
    color: #4e6582;
    font-size: 14px;
}

.site_map_section ul li a {
    text-decoration: none;
}

.site_map_section ul li.content_section {
    color: #083F88;
    font-size: 21px !important;
    font-weight: bold;
}

.subheader {
    font-size: 16px !important;
    font-weight: 600 !important;

}

@media(max-width:767.8px) {
    .hero_image {
        margin-top: 75px;
    }
    .hero_image h1 {
        font-size: 60px;
    }
    .hero_image>h1 {
        padding-top: 25% !important;
    }

    .pageContainer {
        margin-top: 4px;
    }
}



@media(min-width: 768px)and (max-width:991.7px) {
    
    .content_head a {
        font-size: 25px !important;
    }

    .site_map_section ul li.content_head {
        font-size: 25px !important;
    }
}

.site_map_section a:hover {
    color: #337ab7;; /* Change the color to your desired hover color */
    text-decoration: underline; /* Add underline on hover */
  }